<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <div>{{environment}} environment</div>
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld
  },
  computed: {
    environment() {
      return process.env.NODE_ENV
    }
  }
};
</script>
